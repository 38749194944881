import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";

const testimonials = [
  {
    quote: 'Tutorspot has a lot of tutors to choose from and the team has been very helpful in finding the right tutors based on the needs. If something doesn\'t work out, they quickly send more profiles of tutors and give recommendations based on the feedback that they received.',
    name: 'Meena M'
  },
  {
    quote: 'Both tutors have been flexible and efficient and Tutorspot has always been extremely responsive, providing the best guidance on suitable tutors. I cannot recommend Tutorspot highly enough.',
    name: 'Sarah A'
  },
  {
    quote: ' I would definitely recommend Tutorspot to my friends and family, they helped match my daughter to an excellent tutor and are always willing to support with any queries.',
    name: 'Fizzah R'
  },
]
export default function TestimonialsCarousel() {
  return (
    <Container>
      <h2 className="mb-5 text-center">Students Love Tutorspot</h2>
      <Row className="justify-content-center">
        <Col xs={10}>
          <Carousel controls={false}>
            {testimonials.map(testimonial => (
              <Carousel.Item key={testimonial.name}>
                <div className="mb-5">
                  <p className="lead">
                    {testimonial.quote}
                  </p>
                  <p className="text-center">
                  - 
                  {testimonial.name}
                  </p>
                  <p className="text-center">
                    {[...Array(5)].map((_, i) => (
                      <FontAwesomeIcon key={i} icon={faStar} className="text-gold" />
                    ))}
                  </p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  )
}
