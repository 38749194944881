import styles from './LandingPageTile.module.scss';
import { forwardRef } from 'react';
import Image from 'next/image'
import defaultImage from '../public/images/default-tile-cover.png';

const LandingPageTile = forwardRef(({onClick, href, page}, ref) => {
  const imageUrl = page.thumbnail.url || defaultImage.src

  return <>
    <div className={`${styles.imageContainer} mb-2`}>
      <a href={href} onClick={onClick} ref={ref} className={styles.imageLink}>
        <Image
          src={imageUrl}
          className={styles.image}
          alt={page.header1}
          fill
          sizes="100vw" />
      </a>
    </div>

    <a href={href} onClick={onClick} ref={ref} className="d-block text-dark text-center text-decoration-none">
      {page.linkTitle}
    </a>
  </>
})

LandingPageTile.displayName = "LandingPageTile"
export default LandingPageTile
