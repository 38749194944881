import Link from 'next/link'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'

export default function SignUpHero() {
  return (
    <Container>
      <h2 className="mb-5 text-center">Get Started With Tutorspot Today</h2>

      <Row>
        <Col xs={12} md={5} className="offset-md-1">
          <div className="p-4 bg-white rounded mb-4 mb-md-0">
            <div className="text-center mb-3">
              <small className="d-block mb-3 text-uppercase text-muted text-spaced font-weight-bold">
                Become a
              </small>

              <h3>Student</h3>
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Learn from the finest{' '}
              <Link href="/online-tutoring" passHref>
                online tutors
              </Link>{' '}
              in the UK
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Easily connect with the perfect tutor for your needs
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Convenient and automated payment
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Our Concierge Service supports you at every step
            </div>

            <div className="text-center mt-4">
              <Link href="/request-tutor" passHref className="btn btn-info">
                Request a Tutor
              </Link>
            </div>
          </div>
        </Col>

        <Col xs={12} md={5}>
          <div className="p-4 bg-info text-white rounded h-100">
            <div className="text-center mb-3">
              <small className="d-block mb-3 text-uppercase text-spaced font-weight-bold">
                Become a
              </small>

              <h3>Tutor</h3>
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Get discovered by students across the UK
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Build your business or simple tutor a few students
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Get paid quickly and conveniently
            </div>

            <div className="mb-2">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Guidance and support from the Tutorspot team
            </div>

            <div className="text-center mt-4">
              <Link href="/tutor" passHref className="btn btn-light">
                Request a Tutor
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
