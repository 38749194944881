import Head from 'next/head'

import SignUpHero from '../components/SignUpHero'
import PopularSubjectsHero from '../components/PopularSubjectsHero'
import IndexHero from '../components/IndexHero'
import IndexContent from '../components/IndexContent'
import ValuePropositionAccordion from '../components/ValuePropositionAccordion'
import TestimonialsCarousel from '../components/TestimonialsCarousel'

export default function Home() {
  return (
    <div>
      <Head>
        <title>Tutorspot | Online tutoring | Tutors near you</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <IndexHero />

      <div className="mb-5">
        <div className="py-5">
          <div className="container">
            <div className="mb-5 text-center">
              <h2>The Tutorspot Difference</h2>
            </div>
            <ValuePropositionAccordion />
          </div>
        </div>
      </div>

      <div className="mb-5">
        <TestimonialsCarousel />
      </div>

      <div className="bg-white mb-5">
        <IndexContent />
      </div>

      <div className="mb-5">
        <SignUpHero />
      </div>

      <div className="bg-white mt-5 mb-5">
        <PopularSubjectsHero />
      </div>
    </div>
  )
}
