import Link from 'next/link'
import footerData from '../footer-data.preval'
import Container from 'react-bootstrap/Container'
import LandingPageTile from './LandingPageTile'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function PopularSubjectsHero() {
  return (
    <div className="py-5">
      <Container>
        <h2 className="mb-4 text-center">
          Popular Subjects
        </h2>

        <Row>
          {footerData.subjects.map(subject => (
            <Col md={3} xs={12} key={subject.id} className="mb-3">
              <Link href={`/tutoring/${subject.slug}`} passHref legacyBehavior>
                <LandingPageTile page={subject} />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
