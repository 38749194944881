import Link from 'next/link'
import styles from './IndexHero.module.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HeroSearch from './HeroSearch'
import {useRef} from 'react'

export default function IndexHero() {
  const videoRef = useRef()
  const setPlayback = () => {
    videoRef.current.playbackRate = 0.6
  }

  return (
    <div className={styles.heroContainer}>
      <div className={styles.overlay}></div>

      <video ref={videoRef} onCanPlay={setPlayback} playsInline autoPlay muted loop className={styles.coverVideo}>
        <source src="/videos/index-bg-video.mp4" type="video/mp4" />
      </video>

      <Container className="pt-lg-4">
        <Row>
          <Col lg={6} md={8} className="pt-5 pb-lg-4">

            <h1 className={`loud-header text-white pb-2`}>
              Find Qualified Online and Local Tutors
            </h1>
          </Col>
        </Row>

        <Row>
          <Col xl={12} className="pt-2 pt-lg-5">
            <div className="mb-4">
              <HeroSearch />
            </div>

            <div className="bg-primary p-4 text-white text-center rounded mb-3">
              <h5 className="mb-0">
                Tutorspot can help you excel throughout the school year.
                {' '}
                <Link href="/online-tutoring" passHref className="text-white" target="_blank">
                  Learn about our online tutoring.
                </Link>
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
