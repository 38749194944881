import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'next/image'
import peopleSearch from '../public/images/people_search.svg'
import messages from '../public/images/messages.svg'
import learning from '../public/images/learning.svg'
import payOnline from '../public/images/pay_online.svg'
import Link from 'next/link'

export default function IndexContent() {
  return (
    <Row>
      <Col xs={12} md={8} className="mx-md-auto p-5">
        <div className="mb-5 text-center">
          <h2>Find a Great Tutor With a Few Simple Clicks!</h2>
        </div>

        <Row className="mb-5">
          <Col xs={12} md={6} className="p-4 text-center order-md-1">
            <Image
              src={peopleSearch}
              width={300}
              height={200}
              alt="Search tutors"
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </Col>

          <Col xs={12} md={6} className="p-4">
            <h3>
              Browse our directory of{' '}
              <strong className="text-success">qualified</strong> tutors
            </h3>
            <p>
              Simply search for a subject, choose your level and enter your post
              code above to get started
            </p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={12} md={6} className="p-4 text-center">
            <Image
              src={messages}
              width={300}
              height={200}
              alt="Messaging"
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </Col>

          <Col xs={12} md={6} className="p-4">
            <h3>
              <strong className="text-success">Message unlimited tutors</strong>{' '}
              for free
            </h3>
            <p>
              Send as many messages as you want so you can be sure you find the
              right fit
            </p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={12} md={6} className="p-4 text-center order-md-1">
            <Image
              src={learning}
              width={300}
              height={200}
              alt="Easy learning"
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </Col>

          <Col xs={12} md={6} className="p-4">
            <h3>
              Start <strong className="text-success">learning!</strong>
            </h3>
            <p>
              Our{' '}
              <Link href="/online-tutoring" passHref>
                online tutors
              </Link>{' '}
              offer one-to-one online lessons
            </p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={12} md={6} className="p-4 text-center">
            <Image
              src={payOnline}
              width={300}
              height={200}
              alt="Straightforward billing online"
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </Col>

          <Col xs={12} md={6} className="p-4">
            <h3>
              <strong className="text-success">Straightforward billing</strong>{' '}
              so the focus stays on learning
            </h3>
            <p>
              Convenient, automated billing means you don&apos;t have to worry
              about paying tutors with cash
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
