import {Accordion, Card} from 'react-bootstrap'
import AccordionButton, {useAccordionButton} from 'react-bootstrap/AccordionButton'
import AccordionContext from 'react-bootstrap/AccordionContext'
import {createContext, useContext, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'next/image'
import supportImage from '../public/images/undraw_active_support_re_b7sj.svg'
import calendarImage from '../public/images/undraw_booked_re_vtod.svg'
import collaboratorImage from '../public/images/undraw_collaborators_re_hont.svg'
import educationImage from '../public/images/undraw_education_f8ru.svg'


// const GlobalAccordionContext = createContext({
//   globalCurrentEventKey: "0",
//   updateGlobalCurrentEventKey: () => {}
// })


function ContextAwareToggle({children, eventKey, setActive, callback}) {
  const {activeEventKey} = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => {
      callback && callback(eventKey)
      setActive(eventKey)
    }
  )
  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <AccordionButton
      className="bg-transparent"
      onClick={decoratedOnClick}
    >
      {children}
    </AccordionButton>
  )
}

export default function ValuePropositionAccordion() {
  const [activeKey, setActiveKey] = useState("0")

  return (
    <Row>
      <Col xs={12} md={6}>
        <Accordion defaultActiveKey="0">
          <Card className="mb-3">
            <Card.Header className="bg-white">
              <ContextAwareToggle eventKey="0" setActive={setActiveKey}>
                <h5 className="mb-0">
                  Expert tutors, hand-picked by us
                </h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                We select only the best tutors so you don&apos;t have to worry. Our high standard of excellence means
                we accept only expert, qualified tutors.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="bg-white">
              <ContextAwareToggle eventKey="1" setActive={setActiveKey}>
                <h5 className="mb-0">
                  Hands-on service
                </h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                We act as a concierge throughout your tutoring journey. From selecting the perfect tutor through to
                ensuring lessons are scheduled correctly, we&apos;re here to help.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="bg-white">
              <ContextAwareToggle eventKey="2" setActive={setActiveKey}>
                <h5 className="mb-0">
                  Our tutors go above and beyond
                </h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                Tutorspot tutors care about you, and most importantly they care about your outcome. Our tutors love
                what they do and are dedicated to ensuring they help you in every way possible.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-3">
            <Card.Header className="bg-white">
              <ContextAwareToggle eventKey="3" setActive={setActiveKey}>
                <h5 className="mb-0">
                  Tutoring when you need it
                </h5>
              </ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Tutors are available during the day or in the evening, weekdays and weekends. You have a busy schedule
                and we&apos;re here to make sure tutoring fits in perfectly.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
      <Col xs={12} md={6}>
        <div className="p-4">
          {activeKey === "0" &&
            <Image
              src={educationImage}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          }

          {activeKey === "1" &&
            <Image
              src={supportImage}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          }

          {activeKey === "2" &&
            <Image
              src={collaboratorImage}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          }

          {activeKey === "3" &&
            <Image
              src={calendarImage}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          }
        </div>
      </Col>
    </Row>
  )
}
